import React from 'react';

const responseJSON = `{
    "fullName": "string",
    "username": "string",
    "email": "string",
    "token": "string"
}`;

const LoginRoute = () => (
  <div className="LoginRoute" style={{marginLeft: '1rem', maxWidth: '40rem'}}>
      <h1>Login</h1>
      <p>The login endpoint authenticates user credentials and returns an object that contains a token for authorizing
          graphql queries.</p>
      <h2>Route</h2>
      <p><code>POST /login</code></p>
      <h2>Request Parameters</h2>
      <p>Parameters to the login route should be passed in the request body in url encoded format.</p>
      <ul>
          <li><p>username: <code>string</code></p>
              <p>The email you use to log into AreaPulse.</p>
          </li>
          <li><p>password: <code>string</code></p>
              <p>The password you use to log into AreaPulse.</p>
          </li>

      </ul>
      <h2>Responses</h2>
      <p>The login route will return a JSON response to every request. This object has two top level
          fields: <code>error</code> and <code>data</code>. The <code>error</code> field will be empty for successful
          requests, but contain details for failed requests. The <code>data</code> field with contain user data for
          successful requests.</p>
      <h3>200</h3>
      <p>The API successfully authenticated your credentials and is returning a user object that includes the token
          needed to authorize other requests. After getting a success response, you&#39;ll want to cache that token to
          use when querying API data with the graphql endpoint.</p>
      <pre>
          <code className='language-json' lang='json'>
              {responseJSON}
          </code>
      </pre>
      <p>There is other data returned, but you shouldn&#39;t depend on fields not documented here as they may change
          without warning in the future.</p>
      <h3>401</h3>
      <p>The provided credentials were incorrect. The <code>error</code> field in the response has more details.</p>
  </div>
);

export default LoginRoute;