import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { login } from "../api";

const Login = ({user, onLogin}) => {
    const [creds, setCreds] = useState({username: '', password: ''});

    const handleUsernameChange = (evt) => {
        setCreds({
            ...creds,
            username: evt.target.value
        });
    }

    const handlePasswordChange = (evt) => {
        setCreds({
            ...creds,
            password: evt.target.value
        });
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        login(creds.username, creds.password)
            .then( res => {
                const user = res.data.user;
                sessionStorage.setItem('user', JSON.stringify(user))
                onLogin(user);
            })
            .catch( err => console.error(err));
    }

    return user ? <Redirect to="/routes/graphql/playground"/> :
        (<form onSubmit={handleSubmit}>
            <p>
                <label>Email: <input type="text"  value={creds.username} onChange={handleUsernameChange}/></label>
            </p>
            <p>
                <label>Password: <input type="password" value={creds.password} onChange={handlePasswordChange}/></label>
            </p>
            <p>
                <button type="submit">Login</button>
            </p>
        </form>);
};

export default Login;