import React from 'react';
import {Link} from 'react-router-dom';

const responseJSON = `{
    "data": "...",
    "errors": []
}`;

const GraphQL = () => (
    <div className="GraphQL" style={{marginLeft: '1rem', maxWidth: '40rem'}}>
        <h1>GraphQL</h1>
        <p>The primary way of interacting with the AreaPulse API is through the graphql endpoint. This endpoint provides
            access to all of your market reports, contacts, and account info.</p>
        <p>To learn more about Graphql, look at the <a href="https://graphql.org" target="_blank"
                                                       rel="noopener noreferrer">official website</a>. </p>
        <p>To learn about the Areapulse Graphql API, you can use the <Link to="/routes/graphql/playground">interactive
            playgound</Link> to explore the schema and run queries. Please, note that this playground uses your live
            production data. You can login to the playground using your AreaPulse login email and password.</p>
        <h2>Route</h2>
        <p><code>POST /graphql</code></p>
        <h2>Request</h2>
        <p>This endpoint accepts the graphql query in json format in the request body. It mostly follows the standard
            GraphQL format, but we don&#39;t yet support the operationName parameter. The parameters we do support are
            described below.</p>
        <h3>Parameters</h3>
        <ul>
            <li><code>query</code>: The graphql query you want to send to the server.</li>
            <li><code>variables</code>: A map of any graphql variables that should be included in the final query.</li>

        </ul>
        <h3>Authorization</h3>
        <p>To use this endpoint, you need to obtain a token from the <code>/login</code> endpoint.</p>
        <p><code>Authorization: Bearer &lt;your_token&gt;</code></p>
        <h2>Response</h2>
        <p>This endpoint returns a json response that contains the data from the query, and any errors that occurred
            during processing.</p>
        <pre>
            <code className='language-json' lang='json'>
                {responseJSON}
            </code>
        </pre>
        <h3>Success</h3>
        <h4>200</h4>
        <p>The request was successful. The data field will have a data structure with the requested data.</p>
        <h3>Errors</h3>
        <h4>400 </h4>
        <p>There was an error in the request. Most likely, the graphql query was malformed. There will be details in
            the <code>error</code> field.</p>
        <h4>401</h4>
        <p> The api couldn&#39;t authenticate your request. Either you need to provide a token, or the token you&#39;re
            giving is expired.</p>
    </div>
);

export default GraphQL;