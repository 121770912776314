import React from 'react';
import { Link } from 'react-router-dom';

const Introduction = () => (
    <div className="Introduction" style={{marginLeft: '1rem', maxWidth: '40rem'}}>
        <h1>Overview</h1>
        <p>AreaPulse is market report creation tool with built in contact management features. This API gives access to most of AreaPulse&#39;s functionality, allowing you to integrate it with other software such as your own contact management system.</p>
        <h2>Basic Usage</h2>
        <p>The AreaPulse API is located at <a href="https://api.areapulse.com">https://api.areapulse.com</a>. It is primarily a GraphQL API. It has two endpoints that you need to use the API:</p>
        <ul>
            <li><Link to="/routes/graphql">/graphql</Link> - This is the main endpoint in the API. It takes GraphQL queries and returns data for those queries.</li>
            <li><Link to="/routes/login">/login</Link> checks your credentials and returns an access token you can use in the <code>/graphql</code> endpoint.</li>
        </ul>
    </div>
);

export default Introduction;
