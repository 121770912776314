import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import GraphiQL from 'graphiql';

import { graphqlFetch} from "../api";

const Playground = ({user}) => {
    const history = useHistory();

    const fetcher = (token) => async (graphqlParams) => {
        const response = await graphqlFetch(token, graphqlParams);
        if (response.status === false) {
            sessionStorage.removeItem('user');
            console.log(window.history);
            history.push("/routes/graphql");
        }
        return response;
    }

    return user?.token ?
        <GraphiQL fetcher={fetcher(user.token)}/> :
        <Redirect to="/routes/graphql/login"/>;
};

export default Playground;