import React, { useState, useEffect } from 'react';
import { NavLink, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Login from './pages/Login';

import './App.css';
import 'graphiql/graphiql.min.css';
import Playground from "./pages/Playground";
import Introduction from "./pages/Introduction";
import GraphQL from "./pages/GraphQL";
import LoginRoute from "./pages/LoginRoute";

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const user = sessionStorage.getItem("user");
        setUser(JSON.parse(user));
    }, [])

    return (
        <Router>
            <div className="App">
                  <header>
                      <h1 style={{lineHeight: '2rem', fontSize: "1.5rem", fontWeight: "normal"}}>
                          <img src="/images/ap-color.png"
                               alt="AreaPulse"
                               style={{height: '2rem', marginRight: '1rem', verticalAlign: 'text-top'}}/>API Documentation
                      </h1>
                  </header>
                  <div className="main">
                      <nav>
                          <NavLink to="/">Introduction</NavLink>
                          <h3 style={{margin: 0, marginTop: "1rem"}}>Routes</h3>
                          <ul>
                              <li>
                                  <NavLink to="/routes/login" activeClassName="active">POST /login</NavLink>
                              </li>
                              <li>
                                  <NavLink to="/routes/graphql" activeClassName="active">POST /graphql</NavLink>
                              </li>
                          </ul>
                      </nav>
                      <main>
                              <Switch>
                                  <Route path="/routes/login" exact>
                                    <LoginRoute/>
                                  </Route>
                                  <Route path="/routes/graphql" exact>
                                    <GraphQL/>
                                  </Route>
                                  <Route path="/routes/graphql/login" exact>
                                      <Login user={user} onLogin={ user => setUser(user)}/>
                                  </Route>
                                  <Route path="/routes/graphql/playground" exact>
                                      <Playground user={user} onAuthExpired={() => setUser(null)}/>
                                  </Route>
                                  <Route path="/" exact>
                                      <Introduction/>
                                  </Route>
                              </Switch>
                      </main>
                  </div>
            </div>
        </Router>
  );
}

export default App;
