const BASE_URL = 'https://api.areapulse.com';

export const graphqlFetch = async (token, graphQLParams) => {
    console.log(graphQLParams);
    const data = await fetch(
        `${BASE_URL}/graphql`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(graphQLParams),
        }
    );
    return data.json().catch(() => data.text());
};


export const login = async (username, password) => {
    const data = encodeURI(`username=${username}&password=${password}`);

    const responseData = await fetch(
        `${BASE_URL}/login`,
        {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        }
    );
    return responseData.json().catch(() => data.text());
}